

const state = {
    storeId:0
};

const mutations ={
    updateStoreId(state, newStoreId) {
        state.storeId = newStoreId;
    }
};

const getters = {

};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};