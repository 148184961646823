<template>
	<div>
		<div class="topt">
			<div class="left">
				<div>
					<img src="@/assets/logo/Toplogo.png">
				</div>
				<div>
					换呗点单
				</div>
			</div>
			<div class="right">
				企业对公汇款
			</div>
		</div>
		<div class="content">
			<div class="titlet">
				<div><img src="@/assets/careful.png"/></div>
				<div>{{main}}</div>
			</div>
			<div class="infoboxt">
			<div class="tinfo" :data="groupData" key="type">
					<span>汇款至指定账户</span>
			<div>收款户名：{{groupData.name}}</div>
			<div>收款账号：{{ groupData.card_no}}</div>
			<div>收款银行：{{groupData.bank_sub_name }}</div>
			</div>
			<div class="verification">
				<span v-show="right" style="font-size: 18px;font-weight: 500;display: block;margin-bottom: 10px;position: absolute;top: -30px;">安全验证</span>
				<template> 
				<t-form
				ref="form"
				:data="formData"
				:rules="FORM_RULES"
				:showErrorMessage="false"
				label-width="0"
				@submit="onSubmit"
				v-show="right"
				>
				<t-form-item label="验证手机" name="contact_phone" :label-width="75" class="tp">
				<t-input   v-model="formData.contact_phone" :disabled="true" size="large"  >
				</t-input>
				<t-button class="verification-getcode" variant="outline" :disabled="countDown > 0" @click="handleCounter">
				{{countDown == 0 ? '获取验证码' : `${countDown}秒后可重发` }}
				</t-button>
				</t-form-item>
				<t-form-item label="验证码" name="verifyCode" :label-width="75">
				<t-input  style="width: 230px;" v-model="formData.verifyCode" size="large" placeholder="请输入验证码" key="verifyCode" />
				<t-button block size="large"  style="width: 80px;" class="btn-container" @click="login">确定</t-button>
				</t-form-item>
				</t-form>
				</template>
				</div>
				<div calss="bottomt" style="color:#5b5b5b;margin-top: 30px;" v-show="ishow" >
					<span class="t" v-show="right" style="display: block;text-align: left;">已验证通过</span>
					<span style="font-size: 18px;font-weight: 500;display: block;margin-bottom: 10px;position: absolute;left: 55px;">付款方信息</span>
						<!-- <span class="b">付款方信息</span> -->
						<div class="b" style="width: 280px;">公司名：{{ groupData.name }}</div>
						<div class="b">统一社会信用代码：{{ groupData.bank_code }}</div>
						<div class="b">类型：{{ groupData.type == 0 ? '全部' :(groupData.type==1? '预付款':( groupData.type==2? '货款':(groupData.type==3? '保证金':(groupData.type==4? '其他':'')))) }}</div>
						<div class="b">付款金额：{{ groupData.price}}</div>
						<div class="b">付款备注：{{ groupData.remark}}</div>
						<div class="b" style="display: flex;flex-direction: row;align-items: center;">
							汇款凭证
						<div class="b" style="position: relative;left: 10px;width: 110px;height: 110px; border: 1px solid #e1e1e1;border-radius: 2px;box-shadow: -5px -6px -5px -6px rgba(0, 0, 0, 0.2); 
	background-color: #dedede;margin-left: 10px;">
					<!-- 	<div class="upload">
							<div @click="chooseImage"  class="uploadbox">
							<div v-if="!imageData" style="font-size: 30px;">+</div>
						<div v-if="!imageData" style="font-size: 12px;color: #000;opacity: 0.4;" @click="openPDFInNewTab">点击上传文件</div>
							{{this.imageData}}
							</div>
						</div> -->
						<upload :imgsrc.sync="formData.certificate_image" v-model="formData.certificate_image"></upload>
						</div>
						<div class="namebox" style="color: gray;padding-left: 30px;width:260px;" v-show="isImg">图片格式为jpg，jpeg，png,文件大小建议不超过2M</div>
						</div>
						<t-button class="subinfo" v-show="subshow" block size="large"  style="width: 120px;margin-top: 15px;margin-left: 50px;position: absolute;bottom: -120px;" @click="submitInfo">{{buttonText}}</t-button>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { UserIcon,AddRectangleIcon, UploadIcon,SearchIcon,CaretRightSmallIcon,CaretDownSmallIcon}from 'tdesign-icons-vue';
	// import MapComponent from '@/components/map/index.vue';
	import  Upload from "@/components/upLoad/indexTwo.vue";
	const INITIAL_DATA = {
	contact_phone: '',
	verifyCode: '',
	// MapComponent,
	};
	const  FORM_RULES= {
			contact_phone:[{require:true,}],
		verifyCode: [{ required: true, message: '验证码必填', type: 'error' }],
	}
	
	export default{
		// computed:{
		// imageData(){
		// return this.imgsrc
		// }
		// },
		components: {
			Upload,
// UserIcon,AddRectangleIcon,UploadIcon,SearchIcon,CaretRightSmallIcon,CaretDownSmallIcon
		},
		data(){
			return{
				buttonText:'提交汇款信息',
				main:'您可通过以下流程完成企业对公汇款业务。',
				FORM_RULES,
				groupData:{},
				formData: { ...INITIAL_DATA },
				countDown: 0,
				ishow:false,
				subshow:true,
				isImg:true,
				right:true
			}
		},
		mounted: function() {
			if(this.$route.params){
			this.id = this.$route.params.id;
			}
		this.getPublicBillList();
		},
		methods:{
			async getPublicBillList() {
						// const that = this
						this.$request.get('/admin/merchant_login/getPublicBillInfo',{
						params:{
						id:this.id
						}
						}).then(({data}) => {
						console.log(data,'123456');
						if(data.code == 200){
						this.groupData = data.data;
							this.formData=data.data;
							if(this.formData.status==2){
								this.ishow=true;
								this.subshow=false;
								this.isImg=false;
								this.right=false;
								this.main='您已成功提交信息';
							}
							console.log(this.formData.status);
							console.log(this.formData);
						console.log(this.groupData,'123');
						console.log(data.data,'123');
						}
						})
						
					},
		async onSubmit(){
			
		},
		// 验证码登录
		login(){
			this.$request.get('/admin/merchant_login/VerifyBillCode',{
				params:{
				id:this.id,
				phone:this.formData.contact_phone,
				verify_code:this.formData.verifyCode
				}
			}).then(({data}) => {
			console.log(data,'555');
			if(data.code==200){
					this.ishow=true;
			this.$message.success('验证成功');
			}else{
			this.$message.error(data.msg);
			}
			})
		},
		submitInfo(){
			if(!this.formData.certificate_image){
			this.$message.error('请上传凭证');
			return
			}
					// this.$router.push({ name: 'payment'} );
			this.$request.get('/admin/merchant_login/payPublicBill',{
				params:{
				id:this.id,
				certificate_image:this.formData.certificate_image,
				verify_code:this.formData.verifyCode
				}
			}).then(({data}) => {
			console.log(data,'555');
			if(data.code==200){
					this.buttonText='已提交成功';
					this.main='您已成功提交信息';
			this.$message.success('提交成功');
			this.getPublicBillList();
			// this.subshow=false;
			}else{
			this.$message.error(data.msg);
			}
			})
			
		},
		handleCounter() {
		console.log('send');
		this.$request.get('/admin/merchant_login/getBillVerifyCode',{
		//phone:this.formData.phone,
			params:{
			id:this.id,
			phone:this.formData.contact_phone
			}
		}).then(({data}) => {
		console.log(data,'456');
		if(data.code == 200){
		this.$message.success('验证码发送成功');
		}else{
		this.$message.error(data.msg)
		}
		})
		
		this.countDown = 60;
		this.intervalTimer = setInterval(() => {
		if(this.countDown > 0) {
		this.countDown -= 1;
		}else{
		clearInterval(this.intervalTimer);
	this.countDown = 0;
		}
		}, 1000);
		},
	}
	}
</script>

<style>
	body{
		background-color: #eeeeee;
	}
	body,html{
	min-width:320px;/* 这里设置你想要的最低宽度 */
	}
	.topt{
		background-color: white;
		height: 70px;
		width: 100%;
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #ebe9e4;
 box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.2);
 margin-bottom: 30px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	
	.left{
	position: relative;	
	left: 300px;
	}
	.left>div:nth-child(2){
		position: relative;
		top: -10px;
		left: -55px;
		font-size: 10px;
	}
	.right{
		position: relative;
		left: 190px;
		top: 20px;
		font-weight: 800;
	}
	.left >div>img{
		height: 45px;
		line-height: 70px;
	}
	.infoboxt{
		position: absolute;
		left: 500px;
		top: 200px;
	}
	.titlet{
		background-color: white;
		margin-left: auto;
		margin-right: auto;
		width: 50%;
		height: 65px;
		font-size: 17px;
		font-weight: 600;
		display: flex;
		align-items: center;
		padding-left: 20px;
		position: absolute;
		left: 500px;
		top: 120px;
	}
	.titlet>div>img{
		display: flex;
		align-items: center;
		width: 25px;
	}
	.titlet>div:first-child{
		/* padding-right: 5px; */
	}
	.tinfo{
		border-bottom: 1px solid #dddddd;
		box-shadow: 0px 3px 3px -5px rgba(0, 0, 0, 0.2);
		width: 500px;
		text-align: left;
		position: relative;
		left: 60px;
	}
	.tinfo>span{
		font-size: 18px;
		color: dimgray;
		display: block;
		margin-top: 20px;
	}
	.tinfo>div{
		color: dimgray;
		margin-top: 5px;
		line-height: 30px;
	}
	.tinfo>div:last-child{
		padding-bottom: 30px;
	}
	.verification{
		margin-top: 40px;
		position: relative;
		left: 60px;
	}
	.verification>span{
		color: #474747;
	}
	.t{
		padding-bottom: 30px;
		border-bottom: 1px solid #dddddd;
		box-shadow: 0px 3px 3px -5px rgba(0, 0, 0, 0.2);
		width: 600px;
		margin-bottom: 20px;
		position: relative;
		left: 60px;
	}
	.verification-getcode{
		/* position: relative;
		left: -1010px; */
		margin-left: 10px;
	}
	.verification-code{
	}
	.btn-container{
		/* position: relative;
		right: 1065px; */
		margin-left: 10px;
	}
	.b{
		margin-top: 5px;
		text-align: left;
		position: relative;
		left: 60px;
		top: 30px;
	}
	.bottomt{
		margin-top: 40px;
	} 
	.tp{
		width: 400px;
	}
	
	.upload{
	display: flex;
	min-width: 100px;
	min-height: 100px;
width: 100%;
	height: 100%;
	}
	.uploadbox{
	width: 100%;
	height: 100%;
	background-color: #EEEEEE;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	}
</style>