// //index.js
// //导入路由组件
// import VueRouter from 'vue-router'
// //引入组件页面

// import Home from '../components/Home.vue'
// import About from '../components/About.vue'


// // 创建路由对象
// const routes = [{
//             path: "",
//             // 重定项
//             redirect: '/home'
//         }, {
//             path: '/home',
//             name:"home",
//             component: Home,
//             redirect:"Welcome",
//             children:[
       
//     ]
// }, {
//             path: '/about',
//             component: About
//         }]
// const router = new VueRouter({
//     // 配置URL和组价直接的映射关系
//     routes,
//     // history模式 
//     mode: 'history'
// })
// // 将router对象传入到vue实例中
// export default router

//引入组件
import VueRouter from 'vue-router'
// import HomePage from '../components/HomePage.vue'
import Remittance from '../components/Remittance/PayIndex.vue'
// import About from '../components/About.vue'
// import About from '../components/About.vue'

// 创建路由对象
const routes = [{
    path: "/",
    // 重定项
    // redirect: '/Remittance'
}, {
    path: '/Remittance',
    component: Remittance,
    children: [
           {//路由嵌套
			path:'/Remittance/:id',//列表
			component: Remittance
		},
    ]
}]
const router = new VueRouter({
    // 配置URL和组价直接的映射关系
    routes,
    // history模式 
    mode: 'hash'
    // mode: 'history'
})
// 将router对象传入到vue实例中
export default router


 // {//路由嵌套
            //         path: '/HomePage',//列表
            //         component: HomePage
            //     },
