<template>
  <div class="upload">
    <div @click="chooseImage"  class="uploadbox">
      <div v-if="!imageData" style="font-size: 30px;">+</div>
      <div v-if="!imageData" style="font-size: 12px;color: #000;opacity: 0.4;">点击上传图片</div>
      <img v-if="imageData" :src="imageData" alt="" width="100%" height="100%">
    </div>
  </div>
</template>


<script>
import * as qiniu from "qiniu-js";

export default {
  props:{
    imgsrc: {
      type: String,
      required: true
    },
  },
  mounted() {},
  watch: {},
  computed:{
       imageData(){
        return this.imgsrc
       }
  },
  methods: {
     chooseImage() {
      // 创建一个隐藏的 input 元素
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*'; // 只允许选择图片文件
      input.multiple = true; // 允许多选图片

      // 监听 input 元素的 change 事件
      input.addEventListener('change', (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
          // 在这里处理选中的图片文件
          this.qiniuUploadCover(files);
        }
      });

      // 触发 input 元素的点击事件
      input.click();
    },
    async qiniuUploadCover(files) {
        console.log(files[0],'123');

        let file = files[0];
        console.log(file,'filefile');
        let {data} = await this.$request.get('/admin/tool/uploadToken')

        let {token} = data.data;
        let {domain} = data.data;

         // 这里的 result的 domain  是域名
          
          let config = {
            useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: null //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域
          };

          const putExtra = {}
  
          console.log(file,file.name,token,'sdsffesvsrfdsfe');
          // 这里是执行上传  observable是qiniu的订阅实例
          const observable = qiniu.upload(
            file,
            file.name,
            token,
            putExtra,
            config
          );

          const that = this;
          const observer = {
            next(res) {
				console.log(res)
              // next: 接收上传进度信息，res 参数是一个带有 total 字段的 object，包含loaded、total、percent三个属性，提供上传进度信息。
            },
            // error 是失败
            error(err) {
				console.log(err)
              // 上传错误后触发，当不是 xhr 请求错误时，会把当前错误产生原因直接抛出，诸如 JSON 解析异常等；当产生 xhr 请求错误时，参数 err 为一个包含 code、message、isRequestError 三个属性的 object
              //   console.log(err);
              that.$message.error("图片上传失败!");
            },
            // complete是成功
            complete(res) {
                console.log(res);
                that.img = `${domain}/${res.key}`
                console.log(that.img,'imgimg');
                
                that.$emit('update:imgsrc', that.img)
            }
          }

          const subscription = observable.subscribe(observer);
		console.log(subscription)
        }

  }
};
</script>

<style lang="less">
  .upload{
    display: flex;
    // width: 100px;
    // height: 100px;
    min-width: 100px;
    min-height: 100px;
    width: 100%;
    height: 100%;
    .uploadbox{
      // width: 100px;
      // height: 100px;
      width: 100%;
      height: 100%;
      background-color: #EEEEEE;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

</style>