import store from '@/store/modules/stores.ts';
import axios from 'axios';
import proxy from '../config/host.ts';

// const env = import.meta.env.MODE || 'development';

// const API_HOST = env === 'mock' ? '/' : proxy[env].API; // 如果是mock模式 就不配置host 会走本地Mock拦截
// console.log('API_HOST',API_HOST,env);

const CODE = {
  LOGIN_TIMEOUT: 1000,
  REQUEST_SUCCESS: 0,
  REQUEST_FOBID: 1001,
};
let token=localStorage.getItem('token');
let store_id = localStorage.getItem('store_id') ;
console.log(store_id,'123456');



let baseURL= 'https://dev.dining.hbeiwm.com/'
// let baseURL='http://dev.pay.b.hbeiwm.com/'
// let baseURL='https://sh-api.myhbei.com/promoter/'
const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers:{
    'token':token,
    'store-id':store_id,
  }
});

// eslint-disable-next-line
// @ts-ignore
// axios的retry ts类型有问题
instance.interceptors.retry = 3;

instance.interceptors.request.use((config) => config);

instance.interceptors.response.use(
  (response) => {
    if(response.data.code == 101){
        window.localStorage.clear()
        location.reload();
    }
    if (response.status === 200) {
      const { data } = response;
      if (data.code === CODE.REQUEST_SUCCESS) {
        return data;
      }
      return response;
    }

  },
  (err) => {
    const { config } = err;

    if (!config || !config.retry) return Promise.reject(err);

    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= config.retry) {
      return Promise.reject(err);
    }

    config.retryCount += 1;

    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve({});
      }, config.retryDelay || 1);
    });

    return backoff.then(() => instance(config));
  },
);

export default instance;
