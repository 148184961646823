// import Vue from 'vue'
// import App from './App.vue'

// import VueRouter from 'vue-router';
// Vue.use(VueRouter);



// Vue.config.productionTip = false

// new Vue({
// 	VueRouter,
//   render: h => h(App),
// }).$mount('#app')

//引入VueRouter
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import TDesign from 'tdesign-vue';
import axiosInstance from '@/utils/request.ts';
//引入路由器
import router from './router/index.js'

//应用插件
Vue.use(VueRouter)
Vue.use(TDesign)
Vue.prototype.$request = axiosInstance;


/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,//挂载路由规则
    render: h => h(App)
})
